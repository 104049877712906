import React,{ useEffect } from 'react';
import Routes from "./Routes";
import { currentLoader } from "./redux/reducers/loader";
import { useSelector,useDispatch } from "react-redux";
import Snackbars from "./utils/global/Snackbars";
import { setSnackbar, snackObj } from "./redux/reducers/snackbar";
import Loaders from "./utils/global/Loders";
function App() {
  const loading = useSelector(currentLoader);
  const snackbarObj = useSelector(snackObj);
  const dispatch = useDispatch();
  useEffect(() => {
    if (snackbarObj?.snackbarOpen) {
      setTimeout(() => {
        dispatch(
          setSnackbar({
            ...snackbarObj,
            snackbarOpen: false,
          })
        );
      }, 2000);
    }
  }, [snackbarObj?.snackbarOpen]);
  
  return (
    <>
      <Routes />
      {loading ? <Loaders /> : null}
      {snackbarObj?.snackbarOpen?<Snackbars
        snackBarMessage={snackbarObj?.snackbarMessage}
        snackbarOpen={snackbarObj?.snackbarOpen}
        snackBarState={snackbarObj?.snackbarState}
      />:null}
    </>
  );
}

export default App;
