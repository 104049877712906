import { configureStore } from "@reduxjs/toolkit";
import updateUserData from './reducers/user';
import updateMyAddress from "./reducers/myAddress"
import loader from "./reducers/loader";
import snackbar from "./reducers/snackbar";
export default configureStore({
  reducer: {
    updateMyAddress:updateMyAddress,
    updateUserData:updateUserData,
    loader:loader,
    snackbar:snackbar
  },
});
